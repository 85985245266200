.SinglePool1 {


    font-family: Montserrat;
    color: white;
    
    align-items: start;
    font-size: calc(12px + 1vmin);

    
  
  
  


  }
  
  

@media (max-width: 768px) {

  .card {
    width: 100%;

  }
}


.table {

color:black;

border-color: rgb(87, 86, 86);




font-size: 14px;
font-weight: normal;



}


  
  body {
  
  
    background-color: #e8e1eb;

  *background: linear-gradient(-90deg, #102a50, #301d63, #0d203d);
   
  
  *background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  
  
    background-position: top;
    background-repeat:repeat ;
   background-size:cover;
     

   
   
  
    align-items: start;
    
  color:black;  
    
  
  
  
  }
  
  
  .App-header {
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top-center;
    font-size: medium;
    color: white;
  }
  
  
  
  
  
  
  .pointer         { cursor: pointer; }

  


  .card
   
  {
   


    background-color:white;

    



    
border-color:rgb(197, 195, 195);
  
font-family: Montserrat;  

  border-radius: 15px;
  
  font-size: 14px;
  font-weight: 700;
  
  }
  
  
  .card-body {

      

background-color:transparent;

white-space: nowrap;
overflow: auto;
text-overflow: ellipsis;
 



  }

  

  .card-header {

    *color: rgb(218, 53, 176);
    color: whitesmoke;

      }
      
  



  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  
  


  



  
  
  
  
  
    a:link {
  
      color: #3e899e;
  
    }
  
    a:visited {
  
      color: #3e899e;
  
    }
  
  
  
        .input-group-text {

        font-weight: bold;
      }

  
  
  
  
  
  
  

  .dropbtn2 {

  
    background-color:#007bff;
    
    font-style: arial,serif;
    color: white;
    padding: 10px;
    font-size: 15px;
    border: none;
    cursor: pointer;
  
    border-radius: 5px;
  
  }



  .dropdown:hover .dropbtn2 {
    background-color: #3e8e41;
  

  }



  .dropbtn1 {
          
    background-color:#007bff;
    *background-color:#010B13;
    *background-color: rgb(1, 1, 29);
    *background-color: #4CAF50;
    color: white;
    padding: 8px;
    font-size: 15px;
    border: none;
    cursor: pointer;
  
    border-radius: 5px;
  
  }
  


  
  .dropbtn {
          
    background:transparent;
    *background-color:#010B13;
    *background-color: rgb(1, 1, 29);
    *background-color: #4CAF50;
    color: white;
    padding: 5px;
    font-size: 15px;
    border: none;
    cursor: pointer;
  
    
  
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: contents;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: start;
    font-size: 12px;
  }
  
  
  
  
  
  
  /* Links inside the dropdown */
  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  
    cursor: pointer;
  
    font-weight: bold;
  }
  
  
  
  
  
  
  /* Change color of dropdown links on hover */
  .dropdown-content li:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    *background-color: #3e8e41;
    
    
  
  }
  
  .dropdown:hover .dropbtn1 {
    *background-color: #3e8e41;
    
    
  
  }
  



  .animate-charcter
{
   *text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #007bff 0%,
    white 29%,
    #ff1361 67%,
    #DA35B0 100%
    
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 20px;
font-weight: bold;

}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.img-fluid {

border-radius: 0px;

}


.btn {





width:33%;

  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: Montserrat;
  *font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 3rem;
  padding: 0 0.7rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}

@media (min-width: 768px) {
  .btn {
    *padding: 0 2.6rem;
*width:33%;
  }
}




.btn1 {





  width: 45%;
  
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: Montserrat;
    *font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 3rem;
    padding: 0 0.7rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .btn1:hover {
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    transition-duration: .1s;
  }
  
  @media (min-width: 768px) {
    .btn1 {
      *padding: 0 2.6rem;
  *width:33%;
    }
  }
  
  
  .btn2 {





    width: 100%;
    
      background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
      border-radius: 8px;
      border-style: none;
      box-sizing: border-box;
      color: #FFFFFF;
      cursor: pointer;
      flex-shrink: 0;
      font-family: Montserrat;
      *font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
      font-size: 16px;
      font-weight: 700;
      height: 3rem;
      padding: 0 0.7rem;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
      transition: all .5s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }
    
    .btn2:hover {
      box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
      transition-duration: .1s;
    }